import {createRouter, createWebHistory} from 'vue-router';

import {h, resolveComponent} from 'vue'
import DefaultLayout from '../layouts/DefaultLayout'
import AuthLayout from '../layouts/AuthLayout'
import HomeLayout from "../layouts/HomeLayount";

import userService from "../helpers/user.service.js";
import moment from "moment";

function validarRotaRegistro(to, from) {

  if (!["criar", "editar"].includes(to.params?.method.toLowerCase()) && Object.keys(to.params).length < 2) {
    swalToast.fire('Erro', `Método passado inválido, permitidos: [criar, editar]`, 'error');
    return {path: from.path, query: from.query, hash: from.hash}
  }

  if (["editar"].includes(to.params?.method.toLowerCase()) && Object.keys(to.params).length < 2) {
    swalToast.fire('Erro', `O Método atualizar requer o parâmetro id`, 'error');
    return {path: from.path, query: from.query, hash: from.hash}
  }
}

function validarPermissao(to, from) {

  // validação modo aluno
  let modoAluno = (window.localStorage.getItem("idModoAluno") != null)
  if (modoAluno && to.path.split('/')[1] !== 'aluno') {
    swalToast.fire('Erro', `Acesso não permitido enquanto no modo aluno`, 'warning');
    next({
      path: '/aluno/sala-de-aula/disciplina/atuais',
      replace: true
    })
  }

}

function needAuthenticate(path) {

  const rotasLogin = ['/', '/login', '/alterar-senha', '/recuperar-senha'];
  const rotasTeste = ['/teste-upload'];

  if ([...rotasLogin, ...rotasTeste].includes(`/${path.split('/')[1]}`))
    return false

  return true
}

const routes = [
  {
    path: '/',
    name: 'index',
    component: HomeLayout,
    redirect: '/login',
    children: [
      {
        path: '/',
        name: '',
        component: () => import('../views/pages/home/dashboardindex'),
        redirect: '/login',
      },
      {
        path: '/404',
        name: '',
        component: () => import('../views/pages/pages/error/error-404'),
      },
      {
        path: '/500',
        name: '',
        component: () => import('../views/pages/pages/error/error-500'),
      },
      {
        path: '/em-breve',
        name: 'Em Breve',
        component: () => import('../views/pages/pages/error/come-soon'),
      },
      {
        path: '/em-construcao',
        name: 'Em Construção',
        component: () => import('../views/pages/pages/error/under-construction'),
      },
      {
        path: '/teste-upload',
        name: 'teste upload',
        component: () => import('../views/pages/pagesHome/testeUpload'),
      },
    ]
  },
  {
    path: '/auth',
    name: 'auth',
    component: AuthLayout,
    redirect: '/',
    children: [
      {
        path: '/login',
        name: 'Login',
        component: () => import('../views/pages/auth/login'),
      },
      {
        path: '/recuperar-senha',
        name: 'Recuperar Senha',
        component: () => import('../views/pages/auth/recuperar-senha'),
      },
      {
        path: '/alterar-senha/:id/:token?',
        name: 'Alterar Senha',
        component: () => import('../views/pages/auth/alterar-senha'),
      },
    ]
  },
  {
    path: '/inicio',
    name: 'Início',
    component: DefaultLayout,
    redirect: '/',
    children: [
      {
        path: '/inicio',
        name: 'Início',
        component: () => import('../views/pages/pagesHome'),
      },
      // {
      //   path: '/teste-pdf',
      //   name: 'teste pdf',
      //   component: () => import('../views/pages/pagesHome/testePDF'),
      // },
      // {
      //   path: '/teste-upload',
      //   name: 'teste upload',
      //   component: () => import('../views/pages/pagesHome/testeUpload'),
      // },
      {
        path: '/perfil',
        name: 'Meu Perfil',
        component: () => import('../views/pages/Usuario/Perfil'),
      },
      {
        path: '/perfil/alterar-senha',
        name: 'Alterar Minha Senha',
        component: () => import('../views/pages/Usuario/AlterarSenha')
      },
      /*
       * -------------------------------------------------------
       * menus
       */
      {
        path: '/aluno',
        name: 'Sala de Aula',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/aluno/sala-de-aula',
        children: [
          {
            path: '/aluno/notas',
            name: 'Minhas Notas',
            component: () => import('../views/pages/Notas/AlunoNotas'),
            beforeEnter: [validarPermissao],
          },
          {
            path: '/aluno/sala-de-aula/disciplina',
            name: 'Disciplinas',
            component: {
              render() {
                return h(resolveComponent('router-view'))
              },
            },
            redirect: '/aluno/sala-de-aula/disciplina/atuais',
            children: [
              {
                path: '/aluno/sala-de-aula/disciplina/:tipo',
                name: 'Disciplinas',
                component: () => import('../views/pages/SalaDeAula/ListaDisciplinas'),
                beforeEnter: [validarPermissao],
              },
              {
                path: '/aluno/sala-de-aula/disciplina/modulo/:id/:nome?',
                name: 'Módulos',
                component: () => import('../views/pages/SalaDeAula/VisualizarDisciplina'),
                beforeEnter: [validarPermissao],
              },
              {
                path: '/aluno/sala-de-aula/disciplina/modulo/aula/:id/:nome?',
                name: 'Assistir Aula',
                component: () => import('../views/pages/SalaDeAula/Aula'),
                beforeEnter: [validarPermissao],
              },
              {
                path: '/aluno/sala-de-aula/disciplina/modulo/atividade/:id/:nome?',
                name: 'Atividade',
                component: () => import('../views/pages/SalaDeAula/Atividade'),
                beforeEnter: [validarPermissao],
              },
            ]
          },
          {
            path: '/aluno/mensagens',
            name: 'Mensagens ',
            component: () => import('../views/pages/Mensagens/CaixaDeEntrada'),
            beforeEnter: [validarPermissao]
          },
        ],
      },
      {
        path: '/administrativo',
        name: 'Administrativo',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/inicio',
        children: [
          {
            path: '/administrativo/usuario',
            name: 'Usuário',
            component: {
              render() {
                return h(resolveComponent('router-view'))
              },
            },
            redirect: '/administrativo/usuario',
            children: [
              {
                path: '/administrativo/usuario',
                name: 'Usuários Administrativos',
                component: () => import('../views/pages/Usuario/UsuarioLista'),
                beforeEnter: [validarPermissao],
              },
              {
                path: '/administrativo/aluno',
                name: 'Alunos',
                component: () => import('../views/pages/Usuario/UsuarioLista'),
                beforeEnter: [validarPermissao],
              },
              {
                path: '/administrativo/usuario/:method/:id?',
                name: 'Cadastro de Usuário',
                component: () => import('../views/pages/Usuario/UsuarioRegistro'),
                beforeEnter: [validarRotaRegistro, validarPermissao]
              },
            ]
          },
          {
            path: '/administrativo/perfil',
            name: 'Perfil',
            component: {
              render() {
                return h(resolveComponent('router-view'))
              },
            },
            redirect: '/administrativo/perfil',
            children: [
              {
                path: '/administrativo/perfil',
                name: 'Perfil',
                component: () => import('../views/pages/Perfil/PerfilLista'),
                beforeEnter: [validarPermissao]
              },
              {
                path: '/administrativo/perfil/:method/:id?',
                name: 'Cadastro de Perfil ',
                component: () => import('../views/pages/Perfil/PerfilRegistro'),
                beforeEnter: [validarRotaRegistro, validarPermissao]
              },
              {
                path: '/administrativo/perfil/permissoes/:method/:id?',
                name: 'Perfil de Permissões',
                component: () => import('../views/pages/Perfil/PerfilPermissoes/PerfilPermissoes'),
                beforeEnter: [validarRotaRegistro, validarPermissao]
              },
            ]
          },
          {
            path: '/administrativo/instrutor',
            name: 'Professores da Disciplina',
            component: {
              render() {
                return h(resolveComponent('router-view'))
              },
            },
            redirect: '/administrativo/instrutor',
            children: [
              {
                path: '/administrativo/instrutor/:id?',
                name: 'Professores da Disciplina',
                component: () => import('../views/pages/InstrutorDisciplina/InstrutorDisciplina'),
                beforeEnter: [validarPermissao]
              },
            ]
          },
          {
            path: '/administrativo/relatorios',
            name: 'Relatórios',
            component: {
              render() {
                return h(resolveComponent('router-view'))
              },
            },
            redirect: '/administrativo/relatorios',
            children: [
              {
                path: '/administrativo/relatorios/:tipo',
                name: '',
                component: () => import('../views/pages/Relatorios/Atividades'),
                beforeEnter: [validarPermissao]
              },
              {
                path: '/administrativo/relatorios/correcaoatividades',
                name: 'Correção de Atividades',
                component: () => import('../views/pages/Relatorios/CorrecaoAtividades'),
                beforeEnter: [validarPermissao]
              },
            ]
          },
        ]
      },
      {
        path: '/academico',
        name: 'Acadêmico',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/inicio',
        children: [
          {
            path: '/academico/bancoperguntas',
            name: 'Banco de Perguntas',
            component: {
              render() {
                return h(resolveComponent('router-view'))
              },
            },
            redirect: '/academico/bancoperguntas',
            children: [
              {
                path: '/academico/bancoperguntas',
                name: '',
                component: () => import('../views/pages/BancoPerguntas/BancoPerguntasLista'),
                beforeEnter: [validarPermissao]
              },
              {
                path: '/academico/bancoperguntas/:method/:id?/:tab?',
                name: 'Cadastro de Banco de Perguntas',
                component: () => import('../views/pages/BancoPerguntas/BancoPerguntasRegistro'),
                beforeEnter: [validarRotaRegistro, validarPermissao]
              },
            ]
          },
          {
            path: '/academico/disciplina',
            name: 'Disciplina',
            component: {
              render() {
                return h(resolveComponent('router-view'))
              },
            },
            redirect: '/academico/disciplina',
            children: [
              {
                path: '/academico/disciplina',
                name: 'Disciplina',
                component: () => import('../views/pages/Disciplina/DisciplinaLista'),
                beforeEnter: [validarPermissao]
              },
              {
                path: '/academico/disciplina/:method/:id?/:tab?',
                name: 'Cadastro de Disciplina',
                component: () => import('../views/pages/Disciplina/DisciplinaRegistro'),
                beforeEnter: [validarRotaRegistro, validarPermissao]
              },
              {
                path: '/academico/disciplina/modulo/:method/:id?/:tab?',
                name: 'Cadastro de Módulo',
                component: () => import('../views/pages/Modulo/ModuloRegistro'),
                beforeEnter: [validarRotaRegistro, validarPermissao]
              },
              {
                path: '/academico/disciplina/modulo/aula/:method/:id?',
                name: 'Cadastro de Aula',
                component: () => import('../views/pages/Aula/AulaRegistro'),
                beforeEnter: [validarRotaRegistro, validarPermissao]
              },
              {
                path: '/academico/disciplina/modulo/atividade/:method/:id?/:tab?',
                name: 'Cadastro de Atividade',
                component: () => import('../views/pages/Atividade/AtividadeRegistro'),
                beforeEnter: [validarRotaRegistro, validarPermissao]
              },
            ]
          },
          {
            path: '/academico/curso',
            name: 'Cursos',
            component: {
              render() {
                return h(resolveComponent('router-view'))
              },
            },
            redirect: '/academico/curso',
            children: [
              {
                path: '/academico/curso',
                name: 'Cursos',
                component: () => import('../views/pages/Curso/CursoLista'),
                beforeEnter: [validarPermissao]
              },
              {
                path: '/academico/curso/:method/:id?/:tab?',
                name: 'Cadastro de Curso',
                component: () => import('../views/pages/Curso/CursoRegistro'),
                beforeEnter: [validarRotaRegistro, validarPermissao]
              },
            ],
          },
          {
            path: '/academico/turma',
            name: 'Turma',
            component: {
              render() {
                return h(resolveComponent('router-view'))
              },
            },
            redirect: '/academico/turma',
            children: [
              {
                path: '/academico/turma',
                name: 'Turma',
                component: () => import('../views/pages/Turma/TurmaLista'),
                beforeEnter: [validarPermissao]
              },
              {
                path: '/academico/turma/:method/:id?/:tab?',
                name: 'Cadastro de Turma',
                component: () => import('../views/pages/Turma/TurmaRegistro'),
                beforeEnter: [validarRotaRegistro, validarPermissao]
              },
            ]
          },
          {
            path: '/academico/notas',
            name: 'Notas',
            component: {
              render() {
                return h(resolveComponent('router-view'))
              },
            },
            redirect: '/academico/notas',
            children: [
              {
                path: '/academico/notas',
                name: 'Notas',
                component: () => import('../views/pages/Notas/Notas'),
                beforeEnter: [validarPermissao]
              },
            ]
          },
          {
            path: '/academico/mensagens',
            name: 'Mensagens',
            component: () => import('../views/pages/Mensagens/CaixaDeEntrada'),
            beforeEnter: [validarPermissao]
          },
        ] // academico
      },
      {
        path: '/configuracao',
        name: 'Configuração',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/inicio',
        children: [
          {
            path: '/configuracao/log',
            name: 'Log do Sistema',
            component: {
              render() {
                return h(resolveComponent('router-view'))
              },
            },
            redirect: '/configuracao/log',
            children: [
              {
                path: '/configuracao/log',
                name: 'Log do Sistema',
                component: () => import('../views/pages/Log/Log'),
                beforeEnter: [validarPermissao]
              }
            ]
          }
        ] // configuração
      }
    ]
  },
];

export const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  linkActiveClass: 'active',
  routes,
  scrollBehavior() {
    return {top: 0} //// always scroll to top
  },
})

router.beforeEach((to, from, next) => {


  const path = to.path.toLowerCase()
  let user = userService.getLocalUser(false);
  let expirado = true

  if (user) {
    const expires_time = moment.unix(user.created_at).add(user.expires_in / 60, 'minute').format("YYYY-MM-DD HH:mm:ss");
    expirado = (moment(new Date(expires_time)).diff(new Date(), 'minutes') < 1)
  }

  if (needAuthenticate(path)) {
    if (expirado) {
      swalAlert.fire('', 'Sessão não encontrada, entre novamente com a sua conta', 'warning');
      next({
        path: '/login',
        replace: true
      })
    }
  }

  if (path == '/login' && !expirado) {

    if (user.Usuario.Usuario.TipoUsuario == 'administrativo') {
      next({
        path: '/inicio',
        replace: true
      })
      return
    }

    next({
      path: '/aluno/sala-de-aula/disciplina',
      replace: true
    })
    return

  }

  next();

})

