<template>

  <a href="javascript:void(0);" id="btnMenu" class="pull-end p-3"
     @click="alternarMenu(!this.mostrarMenu)">
    <div class="row">
      <div class="col-md-4">
        <span class="bar-icon">
            <span></span>
            <span></span>
            <span></span>
        </span>
      </div>
      <div class="col-md-4 text-menu">
        <div class="me-4">Menu</div>
      </div>
    </div>
  </a>

  <div :class="'floating-menu ' + getMenuTheme" id="floatingMenu" v-if="mostrarMenu">

    <div class="floating-menu-header">
      <img src="../assets/img/uca/uca-logo.svg" class="img-fluid" v-if="getTheme == 'uca'" alt="logo">
      <img src="../assets/img/esn/esn-logo.png" class="img-fluid" v-if="getTheme == 'esn'" alt="logo">
      <a id="menu_close" class="menu-close pull-end" href="javascript:void(0);"
         @click="alternarMenu(false)">
        <i class="fas fa-times"></i>
      </a>
    </div>

    <div :class="'main-menu ' + getMenuTheme">
      <div class="accordion" id="accordionExample">

        <div class="accordion-item">
          <h2 class="accordion-header">
            <button class="accordion-button collapsed"
                    type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapse_login"
                    aria-expanded="false" aria-controls="collapse_login">
              <li class="nav-item user-nav">

                <span class="user-img">
                  <img v-if="usuario && usuario.Foto" :src="usuario.Foto" alt=""/>
                  <img v-else-if="getTheme == 'uca'" src="../assets/img/uca/avatar-icon.png" alt=""/>
                  <img v-else-if="getTheme == 'esn'" src="../assets/img/esn/avatar-icon.png" alt=""/>
                  <span class="status online"></span>
                </span>
                <div class="user-header float-end px-0">
                  <div class="user-text" v-if="usuario" style="width: 132px">
                    <h6 class="m-0">{{ usuario.Nome }} </h6>
                    <p class="text-muted mb-0 text-xs" v-if="usuario.Admin">Administrador</p>
                    <p class="text-muted mb-0 text-xs" v-else>{{ usuario.Email }}</p>
                  </div>
                </div>
              </li>
              <li class="nav-item noti-nav" v-if="temPermissaoMensagem">

                <div class="user-text">
                  <i class="feather-bell fw-bold" style="color: #FF5364 " v-if="(MensagensNotificacao > 0)"
                     title="Você tem novas notificações"></i>
                  <i class="feather-bell fw-bold" v-else title="Nenhuma notificação"></i>
                </div>
              </li>
            </button>
          </h2>
          <div class="accordion-collapse collapse"
               id="collapse_login"
               aria-labelledby="headingOne"
               data-bs-parent="#accordionExample">
            <div class="accordion-body">

              <ul class="submenu" style="display: block !important">
                <li class="has-submenu" v-if="temPermissaoMensagem && MensagensNotificacao > 0">
                  <a class="dropdown-item cursor-pointer" @click="this.$router.push('/aluno/mensagens')">
                    <div class="edu-wrap">
                      <div class="edu-name">
                        <span>{{ MensagensNotificacao }}</span>
                      </div>
                      <div class="edu-detail">
                        <h6 class="text-white">Mensagens não lidas</h6>
                        <p class="text-xs mb-1">Ir para Mensagens <i class="fa-solid feather-chevron-right"/></p>
                      </div>
                    </div>
                  </a>
                </li>
                <li class="has-submenu">
                  <a class="dropdown-item cursor-pointer" @click="this.$router.push('/perfil')">
                    <i class="feather-user me-1"/> Perfil
                  </a>
                </li>
                <li class="has-submenu">
                  <a class="dropdown-item cursor-pointer" @click="this.$router.push('/perfil/alterar-senha')">
                    <i class="feather-lock me-1"></i> Alterar Senha
                  </a>
                </li>
                <li class="has-submenu">
                  <a class="dropdown-item cursor-pointer" @click="logout">
                    <i class="feather-log-out me-1"></i> Sair
                  </a>
                </li>
              </ul>

            </div>
          </div>
        </div>

        <div class="accordion-item" v-for="(menu, m_index) in Menu" :key="m_index">
          <h2 class="accordion-header" id="headingOne">
            <button :class="`accordion-button ` + ((m_index != 0) ? 'collapsed' : '')" type="button"
                    data-bs-toggle="collapse"
                    :data-bs-target="`#collapse_${m_index}`"
                    :aria-expanded=" (m_index == 0) ? 'true' : 'false'" :aria-controls="`collapse_${m_index}`">
              <i :class="menu.icon" v-if="menu.icon"/> {{ menu.Name }}
            </button>
          </h2>
          <div :id="`collapse_${m_index}`" :class="`accordion-collapse collapse` + ((m_index == 0)? ' show' : '')"
               aria-labelledby="headingOne"
               data-bs-parent="#accordionExample">
            <div class="accordion-body">

              <ul class="submenu" style="display: block !important">
                <li v-for="(submenu, sm_index) in menu.Submenu" :key="sm_index"
                    :class="(submenu.Submenu)? 'has-submenu' : ''">

                  <a :href="submenu.to" v-if="submenu?.target == 'new'" target="_new">
                    <i :class="submenu.icon" v-if="submenu.icon"/> {{ submenu.Name }}
                  </a>

                  <router-link :to="submenu.to" v-if="!submenu?.target && submenu.to">
                    <i :class="submenu.icon" v-if="submenu.icon"/> {{ submenu.Name }}
                  </router-link>

                  <a href="javascript:void(0);" v-if="!submenu?.to" class="disabled">{{ submenu.Name }}</a>
                  <ul class="submenu" v-if="(submenu.Submenu)">
                    <li v-for="(submenu1, sm1_index) in submenu.Submenu" :key="sm1_index">
                      <router-link :to="submenu1.to" v-if="submenu1.to">{{ submenu1.Name }}</router-link>
                    </li>
                  </ul>
                </li>

                <li class="" v-if="(usuario?.TipoUsuario == 'aluno' ||  checkModoAluno) && menu.Name == 'Menu'">
                  <a href="javascript:void(0)" @click="modalVideoChamada">
                    <i class="fa-solid fa-phone"/> Vídeo Chamada
                  </a>
                </li>

              </ul>

            </div>
          </div>
        </div>

      </div>
    </div>

  </div>

</template>
<script>
import salaDeAulaService from "../Services/SalaDeAula.service";
import modoAlunoService from "../Services/ModoAluno.service";
import userService from "../helpers/user.service";
import authService from "../Services/Auth.service";
import Helper from "../helpers/helper.service";
import MenuLogin from "../mixins/menu.login.mix";
import eventBus from '../helpers/event.bus';
import moment from "moment";


export default {
  mixins: [MenuLogin],
  computed: {
    getTheme() {
      return Helper.getTheme()
    },
    getMenuTheme() {
      return `menu_${Helper.getTheme()}`
    },
    temPermissaoMensagem() {
      return userService.checkPermision('Mensagem')
    },
  },
  data() {

    const usuario = userService.getUser()

    const menu_completo = [
      {
        Name: 'Menu',
        icon: null,
        Modo: 'aluno',
        Submenu: [
          {
            Name: 'Disciplinas',
            icon: null,
            Controller: 'SalaDeAula',
            to: '/aluno/sala-de-aula/disciplina/atuais',
            active: false,
            Submenu: [
              {
                Name: 'Disciplinas Atuais',
                icon: null,
                Controller: 'SalaDeAula',
                to: '/aluno/sala-de-aula/disciplina/atuais',
                active: false,
              },
              {
                Name: 'Disciplinas Anteriores',
                icon: null,
                Controller: 'SalaDeAula',
                to: '/aluno/sala-de-aula/disciplina/anteriores',
                active: false,
              },
            ]
          },
          {
            Name: 'Minhas Notas',
            icon: null,
            Controller: 'AlunoNotas',
            to: '/aluno/notas',
            active: false
          },
          {
            Name: (usuario.TipoUsuario == 'administrativo') ? 'Caixa De Entrada' : 'Fale com o Tutor/Professor/Coordenador',
            icon: 'fa-regular fa-envelope',
            Controller: 'Mensagem',
            to: '/aluno/mensagens',
            active: false
          },
          {
            Name: 'Fale com o Tutor/Professor/Coordenador',
            icon: 'fa-brands fa-whatsapp',
            Controller: 'FaleComTutor',
            target: 'new',
            to: (Helper.getTheme() == 'uca') ? Helper.getUCAWhatsappLink() : Helper.getESNWhatsappLink(),
            active: false
          }
        ]
      },
      {
        Name: 'Acadêmico',
        icon: null,
        Modo: 'admin',
        Submenu: [
          {
            Name: 'Cursos',
            icon: null,
            Controller: 'Curso',
            to: '/academico/curso',
            active: false
          },
          {
            Name: 'Disciplinas',
            icon: null,
            Controller: 'Disciplina',
            to: '/academico/disciplina',
            active: false
          },
          {
            Name: 'Banco de Perguntas',
            icon: null,
            Controller: 'BancoPergunta',
            to: '/academico/bancoperguntas',
            active: false
          },
          {
            Name: 'Turmas',
            icon: null,
            Controller: 'Turma',
            to: '/academico/turma',
            active: false
          },
          {
            Name: 'Notas',
            icon: null,
            Controller: 'Notas',
            to: '/academico/notas',
            active: false
          },
        ]
      },
      {
        Name: 'Administrativo',
        icon: null,
        Modo: 'admin',
        Submenu: [
          {
            Name: 'Usuários Administrativos',
            icon: null,
            Controller: 'UsuarioAdministrativos',
            to: '/administrativo/usuario',
            active: false
          },
          {
            Name: 'Usuários Alunos',
            icon: null,
            Controller: 'Usuario',
            to: '/administrativo/aluno',
            active: false
          },
          {
            Name: 'Perfil e Permissões',
            icon: null,
            Controller: 'PerfilPermissoes',
            to: '/administrativo/perfil',
            active: false
          },
          {
            Name: 'Professor da Disciplina',
            icon: null,
            Controller: 'InstrutorDisciplina',
            to: '/administrativo/instrutor',
            active: false
          },
          {
            Name: 'Relatórios',
            Controller: 'Relatorios',
            Submenu: [
              {
                Name: 'Relatório de Aulas',
                icon: null,
                Controller: 'Relatorios',
                to: '/administrativo/relatorios/aulas',
                active: false
              },
              {
                Name: 'Relatório de Atividades',
                icon: null,
                Controller: 'Relatorios',
                to: '/administrativo/relatorios/atividades',
                active: false
              },
              {
                Name: 'Relatório de Correção de Atividades',
                icon: null,
                Controller: 'Relatorios',
                to: '/administrativo/relatorios/correcaoatividades',
                active: false
              },
            ]
          }
        ]
      },
      {
        Name: 'Configuração',
        icon: null,
        Modo: 'admin',
        Submenu: [
          {
            Name: 'Log do Sistema',
            icon: null,
            Controller: 'Log',
            to: '/configuracao/log',
            active: false
          },
        ]
      }
    ]

    return {
      Menu_completo: menu_completo,
      Menu: menu_completo,
      mostrarMenu: false,
      usuario: usuario,
      MensagensNotificacao: 0
    }
  },
  beforeMount() {
    this.$router.beforeEach(async (to, from, next) => {

      if (!['/', '/login', '/alterar-senha', '/recuperar-senha'].includes(to.path)) {
        // const user = userService.getLocalUser()
        // const refresh_time = moment.unix(user.created_at).add(1, 'hours').format("YYYY-MM-DD HH:mm:ss");
        // if (moment(new Date(refresh_time)).diff(new Date(), 'minutes') > 60) {
        //   swalLoading.fire('', 'Atualizando token do usuário, aguarde...')
        //   await authService.refreshToken()
        //   swalLoading.close();
        // }

        this.montaMenu()

      }

      next();
    })

    this.$router.afterEach((to) => {

      this.alternarMenu(false)

      if (!['/', '/login', '/alterar-senha', '/recuperar-senha'].includes(to.path)) {
        this.montaMenu()
      }

    })

  },
  mounted() {

    eventBus.addEventListener('MensagensNotificacao', this.receberMensagensNotificacao);

    this.montaMenu();

    window.addEventListener('resize', this.checkResolution);

    this.checkResolution();

  },
  methods: {
    checkModoAluno() {
      return modoAlunoService.checkModoAluno()
    },
    receberMensagensNotificacao(event) {
      this.MensagensNotificacao = event.detail?.mensagens ?? 0;
    },
    beforeUnmount() {
      eventBus.removeEventListener('MensagensNotificacao', this.receberMensagensNotificacao); // Remove o listener
    },
    modalVideoChamada() {
      document.getElementById("btnModalVideoChamada").click();

    },
    checkResolution() {

      const width = window.innerWidth;
      const height = window.innerHeight;

      const self = this;

      var clickMenu = function (event) {

        const menu = document.getElementById('floatingMenu');
        const btnMenu = document.getElementById('btnMenu');

        if (menu && !menu.contains(event.target) && !btnMenu.contains(event.target)) {
          self.alternarMenu(false)
        }

      };

      if (width >= 1920) {
        this.mostrarMenu = true
        window.removeEventListener('click', clickMenu);

      } else {
        this.mostrarMenu = false
        window.addEventListener('click', clickMenu);

      }

    },
    alternarMenu(valor) {
      if (window.innerWidth < 1920)
        this.mostrarMenu = valor;
    },
    filtraMenuModo(menu, modo) {
      menu = menu.filter(item => {
        return item.Modo == modo
      });
      return menu
    },
    montaMenu() {

      const user = userService.getUser()
      const permissoes = userService.getPermissions()

      let filteredMenu = [];

      if (!user.Admin) {

        this.Menu_completo.forEach(menu => {
          let submenu = []
          submenu = menu.Submenu.filter(submenu => (permissoes.some(i => i.Controller == submenu.Controller)))

          if (submenu.length > 0)
            filteredMenu.push({
              Name: menu.Name,
              Submenu: submenu
            })

        })

      } else {

        if (this.checkModoAluno()) {
          filteredMenu = this.filtraMenuModo(this.Menu_completo, 'aluno');

          filteredMenu = filteredMenu.map(i => {
            return {
              ...i,
              Submenu: i.Submenu.map(j => {
                return {
                  ...j,
                  Name: (j.Controller == 'Mensagem' ? 'Fale com o Tutor/Professor/Coordenador' : j.Name)
                }
              })
            }
          })

        } else {

          if (process.env.NODE_ENV == 'development') {
            filteredMenu = this.Menu_completo
          } else {
            filteredMenu = this.filtraMenuModo(this.Menu_completo, 'admin');
          }
        }

        // adiciona o menu para o adminitrador
        filteredMenu = filteredMenu.map(menu => {
          if (menu.Name == 'Acadêmico') {
            if (!menu.Submenu.find(i => i.Controller == 'Mensagem')) {
              menu.Submenu.push({
                Name: 'Caixa de Entrada',
                icon: 'fa-regular fa-envelope',
                Controller: 'Mensagem',
                to: '/aluno/mensagens',
                active: false
              })
            }
          }
          return menu
        })

      }

      this.Menu = filteredMenu

    }
  }
}
</script>
