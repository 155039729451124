<template>

  <a v-if="getTheme == 'uca'" :href="getUCAWhatsappLink" target="_whatsapp"
     class="link-whatsapp link-whatsapp-uca" title="Fale com o tutor"></a>
  <a v-if="getTheme == 'esn'" :href="getESNWhatsappLink" target="_whatsapp"
     class="link-whatsapp link-whatsapp-esn" title="Fale com o tutor"></a>

  <!-- Footer Top -->
  <div class="footer-top">
    <div class="container">
      <div class="row">
        <div class="col-lg-4 col-md-6">
          <div class="footer-widget footer-about">
            <div class="footer-logo">
              <img src="../assets/img/uca/uca-logo.svg" v-if="getTheme == 'uca'" alt="logo">
              <img src="../assets/img/esn/esn-logo.png" v-if="getTheme == 'esn'" alt="logo">
            </div>
            <div class="footer-about-content">
              <p v-if="getTheme == 'uca'">Faculdade Católica Paulista</p>
              <p v-if="getTheme == 'esn'">Escola Superior de Educação e Negócios</p>
            </div>

            <div class="sign-google">
              <ul v-if="getTheme == 'uca'">
                <li>
                  <a href="https://www.instagram.com/faculdadecatolicaead/" target="_instagram">
                    <img src="../../src/assets/img/uca/social/net-icon-instagram.png" class="img-fluid rounded-pill">
                  </a>
                </li>
                <li>
                  <a href="https://facebook.com/ucaead" target="_facebook">
                    <img src="../../src/assets/img/uca/social/net-icon-facebook.png" class="img-fluid rounded-pill">
                  </a>
                </li>
                <li>
                  <a href="https://www.youtube.com/@UcaEduBr" target="_youtube">
                    <img src="../../src/assets/img/uca/social/net-icon-youtube.png" class="img-fluid rounded-pill">
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/school/faculdade-catolica-paulista/?originalSubdomain=br"
                     target="_linkedin">
                    <img src="../../src/assets/img/uca/social/net-icon-linkedin.png" class="img-fluid rounded-pill">
                  </a>
                </li>
              </ul>
              <ul v-if="getTheme == 'esn'">
                <li>
                  <a href="https://www.instagram.com/faculdadeesn" target="_instagram">
                    <img src="../../src/assets/img/uca/social/net-icon-instagram.png"
                         class="img-fluid rounded-pill">
                  </a>
                </li>
                <li>
                  <a href="https://www.facebook.com/esnfaculdade" target="_facebook">
                    <img src="../../src/assets/img/uca/social/net-icon-facebook.png" class="img-fluid rounded-pill">
                  </a>
                </li>
                <li>
                  <a href="https://www.youtube.com/channel/UCEgMW7fL-4Uet5PqDqa_iHA" target="_youtube">
                    <img src="../../src/assets/img/uca/social/net-icon-youtube.png" class="img-fluid rounded-pill">
                  </a>
                </li>
              </ul>
            </div>

          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="footer-widget footer-menu">
            <h2 class="footer-title">Contato</h2>
            <ul v-if="getTheme == 'uca'">
              <li>WhatsApp :<a :href="getUCAWhatsappLink" target="_blank"><b> (14) 9.8826-3000</b></a></li>
              <li><a href="tel:14-3422-1815">Telefone Sede: <b>(14) 3422-1815</b></a></li>
              <li><a href="tel:0800-664-5583"> Central de Atendimento: <b>0800 664 5583</b></a></li>
              <li>E-mail : <a href="mailto:secretaria@uca.edu.br"><strong>secretaria@uca.edu.br</strong></a></li>
            </ul>
            <ul v-if="getTheme == 'esn'">
              <li>WhatsApp :<a :href="getESNWhatsappLink" target="_blank"><b> (14)
                9.8227-2827</b></a></li>
              <li><a href="tel:14-98227-2827">Telefone: <b>(14) 98227-2827</b></a></li>
              <li>E-mail : <a href="mailto:atendimento@esn.edu.br"><strong>atendimento@esn.edu.br</strong></a></li>
            </ul>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="footer-widget footer-contact">
            <h2 class="footer-title">Endereço</h2>
            <div class="footer-contact-info">
              <div class="footer-address">
                <img src="../assets/img/icon/icon-20.svg" alt="" class="img-fluid">
                <a href="https://maps.app.goo.gl/wWsD9QX5fVPa3h6PA" target="_map">Avenida Cristo Rei, 305 – Banzato
                  Marília,<br/> São Paulo – CEP: 17515-200</a>
              </div>
            </div>
            <h2 class="footer-title">Horário de Funcionamento</h2>
            <div class="footer-contact-info">
              <div class="footer-address">
                <p v-if="getTheme == 'uca'">Segunda a quinta, das 8:00 às 20:00 horas
                  <br>As sextas, das 8:00 às 18:00 horas.</p>
                <p v-if="getTheme == 'esn'">Segunda a quinta, das 8:00 às 20:00 horas
                  <br>As sextas, das 8:00 às 18:00 horas.</p>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
  <!-- /Footer Top -->
</template>
<script>
import Helper from "../../src/helpers/helper.service";

export default {
  name: "footertop",
  computed: {
    getTheme() {
      return Helper.getTheme()
    },
    getUCAWhatsappLink() {
      return Helper.getUCAWhatsappLink();
    },
    getESNWhatsappLink() {
      return Helper.getESNWhatsappLink();
    }
  },
}
</script>
